import React from "react";
import styled from "styled-components";
import { breakpoints, colors, eases } from "../styles/variables";
import ImageDivider from "./ImageDivider";
import IconSvgDivider from "../assets/images/divider/icon-divider.svg";

const SectionTitle = styled.div`
  grid-column: 3 / span 8;
  grid-row: 1;
  max-width: 100%;
  text-align: center;
  margin-top: 72px;
  margin-bottom: 32px;

  .text-header {
    font-size: 48px;
    color: ${colors.orange};
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  .text-miniheader {
    margin-bottom: 38px;
    text-align: center;
    color: ${colors.orange};
  }

  &.theme-dark {
    .text-header {
      color: ${colors.lightGreen};
    }
    .text-miniheader {
      color: ${colors.lightGreen};
    }
  }

  &.theme-green-white {
    .text-header {
      color: ${colors.lightGreen};
      margin-bottom: 24px;
    }
    .text-miniheader {
      color: ${colors.white};
    }
  }

  &.theme-white {
    .text-header {
      color: ${colors.white};
    }
    .text-miniheader {
      color: ${colors.white};
    }
  }

  &.media-inquiry {
    .text-header {
      padding-top: 48px;
    }
    .text-miniheader {
      margin-bottom: 24px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;
    margin-top: 36px;

    .text-header {
      font-size: 28px;
    }

    svg,
    .text-header,
    .text-miniheader {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;
    margin-bottom: 16px;
    margin-top: 24px;

    svg {
      width: 50px;
      height: 50px;
      margin-bottom: 8px;
    }
    .text-miniheader {
      margin-bottom: 8px;
    }

    &.media-inquiry {
      .text-miniheader {
        max-width: 60vw;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .text-miniheader {
      [lang="fr-fr"] & {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  /* icon divider */
  .top-icon-divider {
    color: ${colors.orange};
    width: 100%;
    max-width: 224px;
    margin-bottom: 18px;
    height: 50px;
    background: center / contain no-repeat;
    display: inline-block;

    @media (max-width: ${breakpoints.tablet}) {
      max-width: 150px;
    }
  }

  &.theme-dark {
    .top-icon-divider {
      color: ${colors.lightGreen};
    }
  }
`;

interface Props {
  colorTheme: "light" | "dark" | "white" | "green-white";
  title: string;
  hideImageDivider?: boolean;
  sectionType?: "media-inquiry";
}

export default class ButtonText extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const colorClass = `theme-${this.props.colorTheme}`;
    const sectionClass = this.props.sectionType ? this.props.sectionType : "";

    return (
      <SectionTitle className={`${colorClass} ${sectionClass}`}>
        {/* {!this.props.hideImageDivider && <ImageDivider />} */}
        {!this.props.hideImageDivider && <IconSvgDivider className="top-icon-divider" />}
        <div className="text-header">{this.props.title}</div>
        {this.props.children && <div className="text-miniheader">{this.props.children}</div>}
      </SectionTitle>
    );
  }
}
